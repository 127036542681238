import Vue, { inject } from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '../config'
import createPersistedState from 'vuex-persistedstate'
import { addressInput } from './addressInput.module'

const BROWSERS = {
    chrome: 'chrome',
    firefox: 'firefox',
    opera: 'opera',
    safari: 'safari',
    internetExplorer: 'IE',
    edge: 'edge',
    edgeChromium: 'edgeChromium',
    blinkEngine: 'blink',
    unknown: 'unknown',
}

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        status: '',
        loading: false,
        isLogistic: false,
        isSettings: false,
        isGeofence: false,
        isAnalytic: false,
        settingsLock: true,
        isAnalyticFilterPopup:false,
        isOrderWindowClose: true,
        isServiceWindowClose: true,
        width: 450,
        freeCouriers: [],
        orderSend: [],
        secu8: '',
        token: '',
        user: {},
        settings: {},
        geofences: '',
        needSave: '',
        operator_tid: 0,
        userIsPoint: false,
        userIsService: false,
        userIsOperator: false,
        freeCouriersCount: null, 
        showMarkers: { // выполненные заказы, заказы, точки, курьеры, стрелки
            ordersComplete: false,
            servicesComplete: false,
            orders: true,
            services: true,
            points: true,
            couriers: true,
            workers:true,
            arrows: true,
        },
        BROWSERS: BROWSERS,
        operationSystem: '',
        browser: '',
    },
    plugins: [createPersistedState()],
    mutations: {
        window_width: (state, data) => (state.width = data),
        needSave: (state, data) => (state.needSave = data),
        geofences: (state, data) => (state.geofences = data),
        showMarkers: (state, data) => (state.showMarkers = data),
        auth_request(state) {
            console.log('state auth_request')
            state.status = 'loading'
        },
        secu_init(state, data) {
            // секу нужен для того чтобы все аккаунты без поля секу автоматически вышли и потребовали вход через новый пароль
            console.log('state secu_init')
            state.secu8 = 'secu8'
        },
        auth_success(state, data) {
            // console.log('state auth_success', data.token, data.user)
            state.status = 'success'

            state.token = data.token
            state.user = data.user
            state.settings = data.user.settings
            // console.log('data.user', data.user)
            // console.log('data.user.settings', data.user.settings)
            // console.log('state.settings', state.settings)

            if (!state.settings.tz) {
                state.settings.tz = config.tz
            }
            // console.log('tz from store', state.settings.tz)
            axios.defaults.headers.common['tz'] = state.settings.tz

            if (!state.settings.tz_string) {
                state.settings.tz_string = config.tz_string
            }
            // console.log('tz_string from store', state.settings.tz_string)
            axios.defaults.headers.common['tz_string'] = state.settings.tz_string

            if (!state.settings.city) {
                state.settings.city = config.city
            }
            // console.log('city from store', state.settings.city)
            // axios.defaults.headers.common['city'] = state.settings.city

            state.userIsOperator = state.user.role === config.user.role.operator
            state.userIsPoint = state.user.role === config.user.role.point
            state.userIsService = state.user.role === config.user.role.service
            if (state.userIsPoint||state.userIsService) {
                state.operator_tid = state.user.operator_tid
            } else {
                state.operator_tid = state.user.tid
            }
        },
        auth_error(state) {
            console.log('state auth_error')
            state.status = 'error'
        },
        logout(state) {
            console.log('state logout')
            state.status = ''
            state.token = ''
            state.user = ''
            state.settings = ''
            state.userIsPoint= false
            state.userIsService= false
            state.userIsOperator= false
            state.isLogistic= false
            state.isSettings= false
            state.isGeofence= false
            state.isAnalytic= false
            state.settingsLock= true
            state.isAnalyticFilterPopup=false
            state.isOrderWindowClose= true
            state.isServiceWindowClose= true
            state.operator_tid = ''
        },
        settings(state, data) {
            //console.log('state settings', data)
            state.settings = data
        },
    },
    modules: {
        addressInput,
    },
    actions: {
        open_route({ commit }, order) {
            return new Promise((resolve, reject) => {
                console.log('order', order)
                const notifyError = (text) => {
                    Vue.prototype.$notify({
                        text,
                        type: 'error',
                        duration: 4000
                    })
                }
                if (order) {
                    if (order.coordinates) {
                        // const coord = order.coordinates
                        const coord = JSON.parse(order.coordinates)
                        if (coord.length === 2) {
                            const l2 = [coord[0], coord[1]]
                            if (order.finish_addr) {
                                this.dispatch('point_get', order.point_id).then(res => {
                                    if (res.data.success && res.data.point) {
                                        console.log('point_get ok', res.data)
                                        if (res.data.point.coordinates) {
                                            const coord = JSON.parse(res.data.point.coordinates)
                                            if (coord.length === 2) {
                                                const l1 = [coord[0], coord[1]]
                                                
                                                const routerUrl = `https://router.darall.ru?loc=${l1[1]},${l1[0]}&loc=${l2[1]},${l2[0]}`

                                                setTimeout(() => {
                                                    window.open(routerUrl, 'Darall Router')
                                                })
                                                return resolve()
                                            } else {
                                                console.log('open_route fail 1')
                                                notifyError('Не удалось построить маршрут. Не найдены координаты точки (1)')
                                            }
                                        } else {
                                            console.log('open_route fail 2')
                                            notifyError('Не удалось построить маршрут. Не найдены координаты точки (2)')
                                        }
                                    } else {
                                        console.log('point_get fail')
                                        notifyError('Не удалось построить маршрут. Не найдена точка')
                                    }
                                    return reject()
                                })
                            } else {
                                console.log('open_route fail 3')
                                notifyError('Не удалось построить маршрут. Не найден адрес гостя')
                            }
                        } else {
                            console.log('open_route fail 4')
                            notifyError('Не удалось построить маршрут. Не найдено место доставки (1)')
                        }
                    } else {
                        console.log('open_route fail 5')
                        notifyError('Не удалось построить маршрут. Не найдено место доставки (2)')
                    }
                } else {
                    console.log('open_route fail 6')
                    notifyError('Не выбран заказ')
                }
                // return reject()
            })
        },
        async open_multi_route({ commit }, order) {
            let startCoords = ''
            let clientCoords = ''

            if (order && order.orders.every(e => e.coordinates) && Array.isArray(order.orders)) {
                const { orders, pointSequence } = order
                clientCoords = JSON.parse(order.orders[0].coordinates)

                for (let i = 0; i < pointSequence.length; i += 1) {
                    const pointId = pointSequence[i]

                    const foundedOrder = orders.find(e => e.pointIdUuid === pointId)

                    if (typeof foundedOrder !== 'undefined') {
                        await this.dispatch('point_get', foundedOrder.point_id).then(res => {
                            if (res.data.success && res.data.point.coordinates) {
                                const coord = JSON.parse(res.data.point.coordinates)
                                startCoords += `loc=${coord[1]},${coord[0]}&`
                            }
                        })
                    }
                }
            }

            return new Promise((resolve, reject) => {
                const notifyError = (text) => {
                    Vue.prototype.$notify({
                        text,
                        type: 'error',
                        duration: 4000
                    })
                }

                if (clientCoords.length !== 0 && startCoords.length !== 0) {
                    const routerUrl = `https://router.darall.ru?${startCoords}loc=${clientCoords[1]},${clientCoords[0]}`

                    setTimeout(() => {
                        window.open(routerUrl, 'Darall Router')
                    })


                } else {
                    console.log('ope multi route fail')
                    notifyError('Не удалось построить маршрут')
                }
            })
        },
        broadcasts_get({ commit }) {
            return new Promise((resolve, reject) => {
                const params = {
                    operator_tid: this.state.operator_tid,
                }
                axios
                    .get('broadcasts', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.error('get broadcasts err', err.stack)
                        reject(err)
                    })
            })
        },
        broadcast_set({ commit }, broadcast) {
            broadcast.operator_tid = this.state.operator_tid
            return new Promise((resolve, reject) => {
                axios
                    .post('broadcast', broadcast)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.error('post broadcast err', err.stack)
                        reject(err)
                    })
            })
        },
        broadcast_delete({ commit }, id) {
            return new Promise((resolve, reject) => {
                const data = {
                    id
                }
                axios
                    .delete('broadcast', { data })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.error('delete broadcast err', err.stack)
                        reject(err)
                    })
            })
        },
        address_to_coord({ commit }, address) {
            return new Promise((resolve, reject) => {
                const params = {
                    city: this.state.settings.city,
                    address,
                }
                axios
                    .get('address_to_coord', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('address_to_coord err', err.stack)
                        reject(err)
                    })
            })
        },
        geofences_get({ commit }) {
            return new Promise((resolve, reject) => {
                const params = {
                    tid: this.state.operator_tid,
                }
                axios
                    .get('geofences', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('geofences_get err', err.stack)
                        reject(err)
                    })
            })
        },
        geofences_set({ commit }, geofences) {
            return new Promise((resolve, reject) => {
                const params = {
                    data: JSON.stringify(geofences),
                    tid: this.state.user.tid,
                }
                axios
                    .post('geofences', params)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('geofences_set err', err.stack)
                        reject(err)
                    })
            })
        },
        settings_get({ commit }) {
            return new Promise((resolve, reject) => {
                const params = {
                    login: this.state.user.name,
                }
                axios
                    .get('user_settings', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('settings_get err', err.stack)
                        reject(err)
                    })
            })
        },
        settings_set({ commit }, settings) {
            return new Promise((resolve, reject) => {
                const params = {
                    login: this.state.user.name,
                    settings,
                }
                axios
                    .post('user_settings', params)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('settings_set err', err.stack)
                        reject(err)
                    })
            })
        },
        settings_set_id({ commit }, settings){
            return new Promise((resolve, reject) => {
                axios
                    .post('user_settings_id', settings)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('settings_set err', err.stack)
                        reject(err)
                    })
            })
        },
        guest_set({ commit }, guest) {
            return new Promise((resolve, reject) => {
                axios
                    .post('guest', guest)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('guest_set err', err.stack)
                        reject(err)
                    })
            })
        },
        point_set({ commit }, point) {
            return new Promise((resolve, reject) => {
                axios
                    .post('point', point)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('point_set err', err.stack)
                        reject(err)
                    })
            })
        },
        user_operator_get({ commit }) {
            const params = {
                tid: this.state.operator_tid,
            }
            return new Promise((resolve, reject) => {
                axios
                    .get('user_operator_tid', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('user_operator_get err', err.stack)
                        reject(err)
                    })
            })
        },
        guests_rate_get({ commit }, params) {
            // params.operator_tid = this.state.operator_tid

            return new Promise((resolve, reject) => {
                axios
                    .get('guests_rate', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('guests_rate_get err', err.stack)
                        reject(err)
                    })
            })
        },
        call_parsing({commit},params){
            return new Promise((resolve, reject) => {
                axios
                    .get('call_parsing', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('reviews_parsing', err.stack)
                        reject(err)
                    })
            })
        },
        user_get({ commit }, tid) {
            const params = {
                tid,
            }
            return new Promise((resolve, reject) => {
                axios
                    .get('user', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('user_get err', err.stack)
                        reject(err)
                    })
            })
        },
        user_set({ commit }, user) {
            return new Promise((resolve, reject) => {
                axios
                    .post('user', user)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('user_set err', err.stack)
                        reject(err)
                    })
            })
        },
        user_delete({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete('user', { data: { id } })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('user_delete err', err.stack)
                        reject(err)
                    })
            })
        },
        courier_delete_notify({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('courier_delete_notify', { data })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('courier_delete_notify err', err.stack)
                        reject(err)
                    })
            })
        },
        courier_status_notify({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('courier_status_notify', { data })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        courier_disconnect({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('courier_disconnect', {
                        courierId: data.courierId,
                        newOrderStatus: data.newOrderStatus,
                    })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        worker_status_notify({ commit }, data){
            return new Promise((resolve, reject) => {
                axios
                    .post('worker_status_notify', { data })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        users_get({ commit }) {
            return new Promise((resolve, reject) => {
                const params = {
                    operator_tid: this.state.operator_tid,
                }
                axios
                    .get('operator_users', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('users_get err', err.stack)
                        reject(err)
                    })
            })
        },
        account_set({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('darall_account', { ...data })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('account_set err', err.stack)
                        reject(err)
                    })
            })
        },
        users_set({ commit }, users) {
            return new Promise((resolve, reject) => {
                axios
                    .post('users', { users })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('users_set err', err.stack)
                        reject(err)
                    })
            })
        },
        review_set({ commit }, review) {
            return new Promise((resolve, reject) => {
                axios
                    .post('review', review)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('review_set err', err.stack)
                        reject(err)
                    })
            })
        },
        guest_create({ commit }, order) {
            return new Promise((resolve, reject) => {
                axios
                    .post('guest_create', order)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('guest_create err', err.stack)
                        reject(err)
                    })
            })
        },
        assign_courier({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('assign_courier', data)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('assign_courier err', err.stack)
                        reject(err)
                    })
            })
        },
        order_set({ commit }, order) {
            return new Promise((resolve, reject) => {
                axios
                    .post('order', order)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('order_set err', err.stack)
                        reject(err)
                    })
            })
        },
        multi_order_set({ commit }, order) {
            return new Promise((resolve, reject) => {
                axios
                    .post('multi_order', order)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('order_set err', err.stack)
                        reject(err)
                    })
            })
        },
        send_multi_order({ commit }, order) {
            return new Promise((resolve, reject) => {
                axios
                    .post('send_multi_order', order)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('order_set err', err.stack)
                        reject(err)
                    })
            })
        },
        multi_order_reset({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('multi_order_reset', {
                        orderId: data.orderId,
                        newStatus: data.newStatus,
                    })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('multi_order_reset err', err.stack)
                        reject(err)
                    })
            })
        },
        order_reset({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('order_reset', {
                        orderId: data.orderId,
                        newStatus: data.newStatus,
                    })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('order_reset err', err.stack)
                        reject(err)
                    })
            })
        },
        multi_order_delete({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete('multi_order', { data: { id } })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        order_delete({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete('order', { data: { id } })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('order_delete err', err.stack)
                        reject(err)
                    })
            })
        },
        order_delete1({ commit }, orderId) {
            return new Promise((resolve, reject) => {
                axios
                    .post('order_delete', { orderId })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('order_delete err', err.stack)
                        reject(err)
                    })
            })
        },
        multi_order_delete1({ commit }, orderId) {
            return new Promise((resolve, reject) => {
                axios
                    .post('multi_order_delete', { orderId })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('multi_order_delete err', err.stack)
                        reject(err)
                    })
            })
        },
        order_create({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('order_create', {
                        operator_tid: this.state.operator_tid,
                        point_name: data.point_name,
                        order: data.order,
                    })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        order_delete_notify({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('order_delete_notify', {
                        orderId: data.order.id,
                        name: data.order.name,
                        point_id: data.order.point_id,
                        point_tid: data.order.point_tid,
                        status_order: data.order.status,
                        courier_tid: data.order.courier_tid,
                        user: data.user,
                        role: data.userRole,
                    })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('order_delete_notify err', err.stack)
                        reject(err)
                    })
            })
        },
        multi_order_delete_notify({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('multi_order_delete_notify', {
                        orderId: data.order.id,
                        name: data.order.name,
                        point_id: data.order.point_id,
                        point_tid: data.order.point_tid,
                        status_order: data.order.status,
                        courier_tid: data.order.courier_tid,
                        user: data.user,
                        role: data.userRole,
                    })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('order_delete_notify err', err.stack)
                        reject(err)
                    })
            })
        },
        order_cancel_notify({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('order_cancel_notify', {
                        orderId: data.orderId,
                        newStatus: data.newStatus,
                    })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        service_passed_notify({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('service_passed_notify', {
                        name: data.order.name,
                        operator_tid: this.state.operator_tid,
                        worker_tid: data.order.worker_tid,
                    })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        service_cancel_notify({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('service_cancel_notify', {
                        name: data.order.name,
                        point_id: data.order.point_id,
                        point_tid: data.order.point_tid,
                        status_order: data.order.status,
                        worker_tid: data.order.worker_tid,
                        user: data.user,
                        role: data.userRole,
                    })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        restore_order({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .patch('restore_order', {
                        orderId: data.orderId,
                        status: data.status,
                        notify: data.notify,
                        user: data.user,
                        role: data.role,
                        service: data.service
                    })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('restore_order err', err.stack)
                        reject(err)
                    })
            })
        },
        restore_multi_order({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .patch('restore_multi_order', {
                        orderId: data.orderId,
                        status: data.status,
                        notify: data.notify,
                        user: data.user,
                        role: data.role,
                        service: data.service
                    })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('restore_multi_order err', err.stack)
                        reject(err)
                    })
            })
        },
        courier_orders_status({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('courier_orders_status', data)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('courier_orders_status err', err.stack)
                        reject(err)
                    })
            })
        },
        service_set({ commit }, service) {
            return new Promise((resolve, reject) => {
                axios
                    .post('service', service)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('service_set err', err.stack)
                        reject(err)
                    })
            })
        },
        service_get({ commit }, id) {
            return new Promise((resolve, reject) => {
                const params = {id:id}
                axios
                    .get('service', {params})
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('service_get err', err.stack)
                        reject(err)
                    })
            })
        },
        service_specs({commit}){
            return new Promise((resolve, reject) => {
                axios
                    .get('service_specs')
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('service specs err', err.stack)
                        reject(err)
                    })
            })
        },
        couriers_salary({ commit }, data) {
            return new Promise((resolve, reject) => {
                const params = {
                    operator_tid: this.state.operator_tid,
                    date1: data.date1,
                    date2: data.date2,
                    deliveryTime: true,
                }
                axios
                    .get('couriers_salary', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('couriers_salary err', err.stack)
                        reject(err)
                    })
            })
        },
        link_id({ commit }, link_id) {
            return new Promise((resolve, reject) => {
                const params = {
                    link_id: link_id
                }
                axios
                    .get('link_by_id', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('link_id err', err.stack)
                        reject(err)
                    })
            })
        },
        sms_send({ commit }, data) {
            return new Promise((resolve, reject) => {
                const params = {
                    point_id: data.point_id,
                    phone: data.phone,
                    text: data.text,
                }
                axios
                    .get('sms_send', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('sms_send err', err.stack)
                        reject(err)
                    })
            })
        },
        sms_status({ commit }, data) {
            return new Promise((resolve, reject) => {
                const params = {
                    point_id: data.point_id,
                    message_id: data.message_id,
                }
                axios
                    .get('sms_status', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('sms_status err', err.stack)
                        reject(err)
                    })
            })
        },
        sms_balance({ commit }, data) {
            return new Promise((resolve, reject) => {
                const params = {
                    point_id: data.point_id,
                }
                axios
                    .get('sms_balance', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('sms_balance err', err.stack)
                        reject(err)
                    })
            })
        },
        sms_limit({ commit }, data) {
            return new Promise((resolve, reject) => {
                const params = {
                    point_id: data.point_id,
                }
                axios
                    .get('sms_limit', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('sms_limit err', err.stack)
                        reject(err)
                    })
            })
        },
        sms_check({ commit }, data) {
            return new Promise((resolve, reject) => {
                const params = {
                    point_id: data.point_id,
                    api_id: data.api_id,
                }
                axios
                    .get('sms_check', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('sms_check err', err.stack)
                        reject(err)
                    })
            })
        },
        orders_fee_calculate({ commit }, data) {
            return new Promise((resolve, reject) => {
                data.operator_tid = this.state.operator_tid
                data.city = this.state.settings.city
                axios
                    .post('orders_fee_calculate', data)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('orders_fee_calculate err', err.stack)
                        reject(err)
                    })
            })
        },
        orders_deleted({ commit }, data) {
            console.log('orders_deleted start store')
            return new Promise((resolve, reject) => {
                let service = !data.service?false:true
                if (!data.guest_id) data.guest_id = 0
                if (!data.point_id) data.point_id = 0
                if (!data.point_ids) data.point_ids = 0
                if (!data.point_tid) data.point_tid = 0
                if (!data.mode) data.mode = 0
                if (!data.deliveryTime) data.deliveryTime = 0
                const params = {
                    operator_tid: this.state.operator_tid,
                    date1: data.date1,
                    date2: data.date2,
                    guest_id: data.guest_id,
                    point_id: data.point_id,
                    point_ids: data.point_ids,
                    point_tid: data.point_tid,
                    mode: data.mode,
                    deliveryTime: data.deliveryTime,
                }
                if (service) {
                    if (!data.worker) {
                        data.worker = 0
                    }
                    params.worker = data.worker
                    if (!data.service_id) {
                        data.service_id = 0
                    }
                    params.service_id = data.service_id
                } else {
                    if (!data.courier) {
                        data.courier = 0
                    }
                    params.courier = data.courier
                }
                let query = service ? 'service_orders_deleted' : 'orders_deleted'
                console.log(query)
                axios
                    .get(query, { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('orders_deleted err', err.stack)
                        reject(err)
                    })
            })
        },
        multi_orders_deleted({ commit }, data) {
            console.log('multi_orders_deleted start store')
            return new Promise((resolve, reject) => {
                if (!data.guest_id) data.guest_id = 0
                if (!data.point_id) data.point_id = 0
                if (!data.point_ids) data.point_ids = 0
                if (!data.point_tid) data.point_tid = 0
                if (!data.mode) data.mode = 0
                if (!data.deliveryTime) data.deliveryTime = 0

                const params = {
                    operator_tid: this.state.operator_tid,
                    date1: data.date1,
                    date2: data.date2,
                    guest_id: data.guest_id,
                    point_id: data.point_id,
                    point_ids: data.point_ids,
                    point_tid: data.point_tid,
                    mode: data.mode,
                    deliveryTime: data.deliveryTime,
                }

                if (!data.courier) data.courier = 0
                params.courier = data.courier

                axios
                    .get('multi_orders_deleted', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('orders_deleted err', err.stack)
                        reject(err)
                    })
            })
        },
        orders_date({ commit }, data) {
            return new Promise((resolve, reject) => {
                let service = !data.service?false:true
                if (service) return
                if (!data.guest_id) data.guest_id = 0
                if (!data.point_id) data.point_id = 0
                if (!data.point_ids) data.point_ids = 0
                if (!data.point_tid) data.point_tid = 0
                if (!data.mode) data.mode = 0
                if (!data.deliveryTime) data.deliveryTime = 0
                const params = {
                    operator_tid: this.state.operator_tid,
                    date1: data.date1,
                    date2: data.date2,
                    guest_id: data.guest_id,
                    point_id: data.point_id,
                    point_ids: data.point_ids,
                    point_tid: data.point_tid,
                    mode: data.mode,
                    deliveryTime: data.deliveryTime,
                }
                if(service){
                    if(!data.worker) data.courier = 0
                    params.worker=data.worker
                    if(!data.service_id) data.service_id=0
                    params.service_id=data.service_id
                }else {
                    if(!data.courier) data.courier = 0
                    params.courier=data.courier
                }
                let query = service?'service_orders_date':'orders_date'

                axios
                    .get(query, { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('err orders_date', err.stack)
                        reject(err)
                    })
            })
        },
        multi_orders_date({ commit }, data) {
            return new Promise((resolve, reject) => {
                if (!data.guest_id) data.guest_id = 0
                if (!data.point_id) data.point_id = 0
                if (!data.point_ids) data.point_ids = 0
                if (!data.point_tid) data.point_tid = 0
                if (!data.mode) data.mode = 0
                if (!data.deliveryTime) data.deliveryTime = 0

                const params = {
                    operator_tid: this.state.operator_tid,
                    date1: data.date1,
                    date2: data.date2,
                    guest_id: data.guest_id,
                    point_id: data.point_id,
                    point_ids: data.point_ids,
                    point_tid: data.point_tid,
                    mode: data.mode,
                    deliveryTime: data.deliveryTime,
                }

                if (!data.courier) data.courier = 0
                params.courier = data.courier

                axios
                    .get('multi_orders_date', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('err orders_date', err.stack)
                        reject(err)
                    })
            })
        },
        all_orders_date({ commit }, data) {
            return new Promise((resolve, reject) => {
                if (!data.guest_id) data.guest_id = 0
                if (!data.point_id) data.point_id = 0
                if (!data.point_ids) data.point_ids = 0
                if (!data.point_tid) data.point_tid = 0
                if (!data.mode) data.mode = 0
                if (!data.deliveryTime) data.deliveryTime = 0

                const params = {
                    operator_tid: this.state.operator_tid,
                    date1: data.date1,
                    date2: data.date2,
                    guest_id: data.guest_id,
                    point_id: data.point_id,
                    point_ids: data.point_ids,
                    point_tid: data.point_tid,
                    mode: data.mode,
                    deliveryTime: data.deliveryTime,
                }

                if (!data.courier) data.courier = 0
                params.courier = data.courier

                axios
                    .get('all_type_orders_date', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('err orders_date', err.stack)
                        reject(err)
                    })
            })
        },
        objects({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get('objects')
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('objects err', err.stack)
                        reject(err)
                    })
            })
        },
        point_users({ commit }, data) {
            return new Promise((resolve, reject) => {
                let params
                if (data) {
                    params = {
                        point_name: data.point_name,
                    }
                } else {
                    params = {
                        operator_tid: this.state.operator_tid,
                    }
                }
                axios
                    .get('point_users', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('point_users err', err.stack)
                        reject(err)
                    })
            })
        },
        only_point_users({ commit }, data) {
            return new Promise((resolve, reject) => {
                let params
                if (data) {
                    params = {
                        point_name: data.point_name,
                    }
                } else {
                    params = {
                        operator_tid: this.state.operator_tid,
                    }
                }
                axios
                    .get('only_point_users', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('point_users err', err.stack)
                        reject(err)
                    })
            })
        },
        point_get({ commit }, point_id) {
            return new Promise((resolve, reject) => {
                const params = {
                    id: point_id,
                }
                axios
                    .get('point', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('point_get err', err.stack)
                        reject(err)
                    })
            })
        },
        point_update({ commit }, point) {
            return new Promise((resolve, reject) => {
                const params = {
                    point,
                }
                axios
                    .post('point', point)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('point_update err', err.stack)
                        reject(err)
                    })
            })
        },
        points_get({ commit }) {
            return new Promise((resolve, reject) => {
                const params = {
                    operator_tid: this.state.operator_tid,
                }
                axios
                    .get('points', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('points_get err', err.stack)
                        reject(err)
                    })
            })
        },
        points_get_all({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get('points_all')
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('points_get_all err', err.stack)
                        reject(err)
                    })
            })
        },
        guests_get({ commit }, data) {
            return new Promise((resolve, reject) => {
                if (!data.point_id) data.point_id = 0
                if (!data.dateCreate) data.dateCreate = 0
                if (!data.dateVisit) data.dateVisit = 0
                const params = {
                    operator_tid: this.state.operator_tid,
                    date1: data.date1,
                    date2: data.date2,
                    point_id: data.point_id,
                    dateCreate: data.dateCreate,
                    dateVisit: data.dateVisit,
                }
                axios
                    .get('guests', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('guests_get err', err.stack)
                        reject(err)
                    })
            })
        },
        guest_get({ commit }, data) {
            return new Promise((resolve, reject) => {
                const params = {
                    id: data.id,
                    phone: data.phone,
                }
                axios
                    .get('guest', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('guest_get err', err.stack)
                        reject(err)
                    })
            })
        },
        points_set({ commit }, points) {
            return new Promise((resolve, reject) => {
                axios
                    .post('points', points)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('points_set err', err.stack)
                        reject(err)
                    })
            })
        },
        points_update_coordinates({ commit }) {
            return new Promise((resolve, reject) => {
                const params = {
                    city: this.state.settings.city,
                    operator_tid: this.state.operator_tid,
                }
                axios
                    .post('points_update_coordinates', params)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('points_update_coordinates err', err.stack)
                        reject(err)
                    })
            })
        },
        point_update_coordinates({ commit }, point_id) {
            return new Promise((resolve, reject) => {
                const params = {
                    city: this.state.settings.city,
                    point_id,
                }
                axios
                    .post('point_update_coordinates', params)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('point_update_coordinates err', err.stack)
                        reject(err)
                    })
            })
        },
        point_delete({ commit }, data) {
            console.log('point_delete', data)
            return new Promise((resolve, reject) => {
                axios
                    .delete('point', { data })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('point_delete err', err.stack)
                        reject(err)
                    })
            })
        },
        point_address({ commit }, data) {
            return new Promise((resolve, reject) => {
                const params = {
                    city: this.state.settings.city,
                    point_name: data.point_name,
                    point_id: data.point_id,
                    address: data.address,
                    lat: data.lat,
                    lng: data.lng,
                }
                axios
                    .get('point_address', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('point_address err', err.stack)
                        reject(err)
                    })
            })
        },
        price_distance({ commit }, data) {
            return new Promise((resolve, reject) => {
                const params = {
                    operator_tid: data.operator_tid ?? this.state.operator_tid,
                    distance: data.distance,
                }
                axios
                    .get('price_distance', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('price_distance err', err.stack)
                        reject(err)
                    })
            })
        },
        address_to_geofence({ commit }, address) {
            return new Promise((resolve, reject) => {
                const params = {
                    city: this.state.settings.city,
                    operator_tid: this.state.operator_tid,
                    address,
                }
                axios
                    .get('address_to_geofence', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('address_to_geofence err', err.stack)
                        reject(err)
                    })
            })
        },
        couriers_get({ commit }, data) {
            return new Promise((resolve, reject) => {
                const params = {
                    operator_tid: this.state.operator_tid,
                    online_only: data?.online_only ? 1 : 0,
                }
                axios
                    .get('couriers', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('couriers_get err', err.stack)
                        reject(err)
                    })
            })
        },
        workers_get_all({ commit }){
            return new Promise((resolve, reject) => {
                axios
                    .get('workers_all')
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('workers_get err', err.stack)
                        reject(err)
                    })
            })
        },
        workers_with_all_orders({ commit },data){
            return new Promise((resolve, reject) => {
                
                if(!data.service_id) data.service_id=0
                let params={
                    service_id:data.service_id
                }
                axios
                    .get('workers_with_all_orders',{params})
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('workers_with_all_orders err', err.stack)
                        reject(err)
                    })
            })
        },
        worker_delete_notify({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('worker_delete_notify', { data })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('worker_delete_notify err', err.stack)
                        reject(err)
                    })
            })
        },
        worker_orders_status({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('worker_orders_status', data)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('worker_orders_status err', err.stack)
                        reject(err)
                    })
            })
        },
        service_users({ commit }, data) {
            return new Promise((resolve, reject) => {
                let params
                if (data) {
                    params = {
                        service_name: data.service_name,
                    }
                } else {
                    params = {
                        operator_tid: this.state.operator_tid,
                    }
                }
                axios
                    .get('service_users', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('service_users err', err.stack)
                        reject(err)
                    })
            })
        },
        services_with_all_orders({commit}){
            return new Promise((resolve, reject) => {
                axios
                    .get('services_with_all_orders')
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('services_with_all_orders err', err.stack)
                        reject(err)
                    })
            })
        },
        services_get({commit}){
            return new Promise((resolve, reject) => {
                axios
                    .get('services')
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('services_get err', err.stack)
                        reject(err)
                    })
            })
        },
        services_get_all({commit}){
            return new Promise((resolve, reject) => {
                axios
                    .get('services_all')
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('services_all err', err.stack)
                        reject(err)
                    })
            })
        },
        service_delete({ commit }, id) {
            console.log('service_delete id', id)
            return new Promise((resolve, reject) => {
                axios
                    .delete('service', { data: { id } })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('service_delete err', err.stack)
                        reject(err)
                    })
            })
        },
        couriers_orders_today_get({ commit }, point_id) {
            return new Promise((resolve, reject) => {
                const params = {
                    operator_tid: this.state.operator_tid,
                    orders_today: 1,
                    point_id,
                }
                axios
                    .get('couriers', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('couriers_orders_today_get err', err.stack)
                        reject(err)
                    })
            })
        },
        logistics({ commit }, pointId) {
            return new Promise((resolve, reject) => {
                // const params = {
                //     // operator_tid: this.state.operator_tid,
                //     // orders_today: 1,
                //     pointId,
                // }
                axios
                    .post('logistics', { pointId })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('logistics err', err.stack)
                        reject(err)
                    })
            })
        },
        auth({ commit }, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                commit('secu_init')

                axios
                    .post('login', {
                        name: user.name,
                        pass: user.password,
                    })
                    .then(resp => {
                        if (resp.data.success) {
                            axios.defaults.headers.common['Authorization'] = 'Bearer ' + resp.data.token
                            commit('auth_success', resp.data)
                        }
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('auth err', err.stack)
                        reject(err)
                    })
            })
        },
        logout({ commit }) {
            return new Promise((resolve, reject) => {
                commit('logout')
                this.state.geofences = {}
                delete axios.defaults.headers.common['Authorization']
                resolve()
            })
        },
        quick_answers_get({ commit }, id) {
            return new Promise((resolve, reject) => {
                const params = {
                    point_id: id,
                }
                axios
                    .get('/quick_answers', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('quick_answers_get err', err.stack)
                        reject(err)
                    })
            })
        },
        quick_answer_create({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/quick_answer', {
                        name: data.name,
                        point_id: data.point_id,
                        food: data.food,
                        delivery: data.delivery,
                        isShowed: data.isShowed,
                    })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('quick_answer_create err', err.stack)
                        reject(err)
                    })
            })
        },
        quick_answers_update({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/quick_answers', {
                        id: data.id,
                        name: data.name,
                        point_id: data.point_id,
                        food: data.food,
                        delivery: data.delivery,
                        isShowed: data.isShowed,
                    })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('quick_answers_update err', err.stack)
                        reject(err)
                    })
            })
        },
        quick_answers_delete({ commit }, id_answer) {
            return new Promise((resolve, reject) => {
                const params = {
                    id: id_answer,
                }
                axios
                    .delete('/quick_answers', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('quick_answers_delete err', err.stack)
                        reject(err)
                    })
            })
        },
        point_fieldOfActivity_update({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/points_field_of_activity', payload)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('point_fieldOfActivity_update err', err.stack)
                        reject(err)
                    })
            })
        },
        point_average_check({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/point_average_check', payload)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('point_average_check err', err.stack)
                        reject(err)
                    })
            })
        },
        get_media_review({ commit }, id) {
            return new Promise((resolve, reject) => {
                const params = {
                    review_id: id,
                }
                axios
                    .get('/review/media', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('get_media_review err', err.stack)
                        reject(err)
                    })
            })
        },
        get_media_file({ commit }, path_file) {
            return new Promise((resolve, reject) => {
                const params = {
                    path: path_file,
                }
                axios
                    .get('/media_file', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('get_media_file err', err.stack)
                        reject(err)
                    })
            })
        },
        get_proceeds_analytic({ commit }, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/get_proceeds_analytic', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        get_cancelled_orders_analytics({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/get_cancelled_orders_analytics', data)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        get_guests_analytic({ commit }, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/get_guests_analytic', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        get_average_price_delivery({ commit }, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/get_average_price_delivery', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        reviews_date({ commit }, data) {
            return new Promise((resolve, reject) => {
                const params = {
                    operator_tid: this.state.operator_tid,
                    date1: data.date1,
                    date2: data.date2,
                    point_id: data.point_id,
                    courier_tid: data.courier_tid,
                }
                axios
                    .get('reviews_date', { params })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('reviews_date err', err.stack)
                        reject(err)
                    })
            })
        },
        review_parsers_get({ commit }, pointId) {
            return new Promise((resolve, reject) => {
                axios
                    .post('review_parser/list', {
                        pointId,
                    })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('review parser get err', err.stack)
                        reject(err)
                    })
            })
        },
        review_parsed_delete({ commit }, reviewParsedId) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/review_parsed/delete', {
                        reviewParsedId,
                    })
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        reviews_parsed_get({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/review_parsed/list', {
                        dateStart: data.dateStart,
                        dateEnd: data.dateEnd,
                        pointId: data.pointId,
                    })
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        review_parsed_set({commit}, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('review_parsed/update', data)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('parsed review set err', err.stack)
                        reject(err)
                    })
            })
        },
        review_parser_set({commit}, data) {
            return new Promise((resolve, reject) => {
                if (data.id) {
                    axios
                        .post('review_parser/update', data)
                        .then(resp => {
                            resolve(resp)
                        })
                        .catch(err => {
                            console.log('review parser update err', err.stack)
                            reject(err)
                        })
                } else {
                    axios
                        .post('review_parser/create', {
                            ...data,
                            organizationId: this.state.user.organizationId
                        })
                        .then(resp => {
                            resolve(resp)
                        })
                        .catch(err => {
                            console.log('review parser create err', err.stack)
                            reject(err)
                        })
                }
            })
        },
    },
    getters: {
        isChrome(state, getters) {
            if (state.browser === BROWSERS.chrome) return true
            if (getters.getBrowser === BROWSERS.chrome) return true
            return false
        },
        getBrowser(state) {
            if (state.browser != '') return state.browser
            const userAgent = navigator.userAgent
            if (userAgent.match(/chrome|chromium|crios/i)) {
                state.browser = BROWSERS.chrome
            } else if (userAgent.match(/firefox|fxios/i)) {
                state.browser = BROWSERS.firefox
            } else if (userAgent.match(/safari/i)) {
                state.browser = BROWSERS.safari
            } else if (userAgent.match(/opr\//i)) {
                state.browser = BROWSERS.opera
            } else if (userAgent.match(/edg/i)) {
                state.browser = BROWSERS.edge
            } else {
                state.browser = BROWSERS.unknown
            }
            return state.browser
        },
        getOS(state) {
            if (state.operationSystem !== '') return state.operationSystem
            state.operationSystem = (function () {
                const userAgent = navigator.userAgent || navigator.vendor || window.opera
                if (/windows phone/i.test(userAgent)) {
                    return 'Windows Phone'
                }
                if (/android/i.test(userAgent)) {
                    return 'Android'
                }
                if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                    return 'iOS'
                }
                return 'unknown'
            })()
            return state.operationSystem
        },
        isLoggedIn: state => {
            return state.token && state.token.length && !!state.secu8
        },
    },
})
