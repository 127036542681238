<template>
    <div style="height: 100%">
        <v-navigation-drawer app v-model="drawer" :width="230" :fixed="true" :color="'#031E4D'" dark>
            <div class="d-flex flex-column nav-cont" >
                <v-list dense nav class="py-0">
                    <v-list-item two-line :class="miniVariant && 'px-0'">
                        <!-- <img width="36" src="../assets/images/DarallPro_sign_screen.svg" />
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <td class="tooltip-text" v-bind="attrs" v-on="on">
                                    <img class="shapka" src="../assets/images/shapka.png" />
                                </td>
                            </template>
                            <span>C Новым 2024 годом!</span>
                            <span>C Наступающим 2024 годом!</span>
                        </v-tooltip>

                        <v-list-item-content class="ml-6">
                            Darall.pro 🎄
                            <v-list-item-subtitle>DELIVERY CONSOLE</v-list-item-subtitle>
                        </v-list-item-content> -->

                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <td class="tooltip-text" v-bind="attrs" v-on="on">
                                    <img src="../assets/images/DarallPro_logo_invert_screen_transparent.svg"
                                        class="mx-0 mt-4" width="150" />
                                </td>
                            </template>
                            <span>darall.pro - delivery console</span>
                        </v-tooltip>

                        <!-- <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <td class="tooltip-text" v-bind="attrs" v-on="on">
                                    <img src="../assets/images/christmasLogo.svg"
                                        class="mx-0 mt-4" width="150" />
                                </td>
                            </template>
                            <span>darall.pro - delivery console</span>
                        </v-tooltip> -->
                    </v-list-item>

                    <v-divider></v-divider>

                    <div class="d-flex flex-column">
                        
                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <td class="tooltip-text" v-bind="attrs" v-on="on">
                                    <button class="btn-adds btn-yellow" @click="clickCollabs">
                                        Коллаборации
                                        <span class="icon-adds-red">New!</span>
                                    </button>
                                </td>
                            </template>
                            <span><b>Платформа для совместных заказов</b> позволит вам увеличить количество заказов 🤑</span>
                        </v-tooltip>

                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <td class="tooltip-text" v-bind="attrs" v-on="on">
                                    <button class="btn-adds btn-pure" @click="clickHotBox">
                                        Горячий бокс
                                        <span class="icon-adds-yellow">New!</span>
                                    </button>
                                </td>
                            </template>
                            <span><b>Всегда горячая еда</b> 🔥, перестань зависеть от пробок и плохой погоды 🌧</span>
                        </v-tooltip>

                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <td class="tooltip-text" v-bind="attrs" v-on="on">
                                    <button class="btn-adds btn-adds-left btn-clear" @click="clickReviews">
                                        Отзывы 2Гис Яндекс ...
                                    </button>
                                </td>
                            </template>
                            <span><b>Отзывы из 2Гис, Яндекс и др.</b> - Парсинг отзывов с внешних площадок!</span>
                        </v-tooltip>

                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <td class="tooltip-text" v-bind="attrs" v-on="on">
                                    <button class="btn-adds btn-adds-left btn-clear" @click="clickMarket">
                                        Маркет
                                    </button>
                                </td>
                            </template>
                            <span><b>Маркет</b> - Ваш красивый сайт за полчаса и бесплатно!</span>
                        </v-tooltip>

                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <td class="tooltip-text" v-bind="attrs" v-on="on">
                                    <button class="btn-adds btn-adds-left btn-clear" @click="clickYourCourier">
                                        Твои Курьеры
                                    </button>
                                </td>
                            </template>
                            <span><b>Твои Курьеры</b> - Сделать доставку бесплатной и быстрой!</span>
                        </v-tooltip>
                    </div>

                    <div v-for="(item, index) in menuItems" :key="index">
                        <v-list-item link :to="{ name: item.name }" @click="menuItemChange(item.name)">
                            <div v-if="item.badge" class="d-flex">
                                <v-list-item-icon>
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                        
                                <v-list-item-content>
                                    {{ $t('menu.' + item.name) }}
                                </v-list-item-content>
                        
                                <v-chip class="ma-2" color="red" small>
                                    {{ item.badge }}
                                </v-chip>
                            </div>
                        
                            <div v-else class="d-flex">
                        
                                <v-list-item-icon>
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                        
                                <v-list-item-content>
                                    {{ $t('menu.' + item.name) }}
                                </v-list-item-content>
                        
                            </div>
                        </v-list-item>
                    
                    </div>

                </v-list>

                <hr class="break-line mx-2 mt-2">
            </div>
       
        </v-navigation-drawer>

        <v-app-bar class="main-bar" :elevation="1" height="64" app>

            <v-app-bar-nav-icon class="ml-0" @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title class="pl-2">{{ toolbarTitle }}</v-toolbar-title>

            <manage-settings v-if="this.$store.state.isSettings && !this.$store.state.settingsLock"></manage-settings>
            <manage-geofence v-if="this.$store.state.isGeofence"></manage-geofence>

            <v-btn v-if="(this.$store.state.isLogistic && this.$store.state.userIsOperator)" @click="windowOpenClose(false)" x-small color="deep-purple accent-4" dark class="ml-5">
                Управление заказами
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn v-if="this.$store.state.isAnalytic" @click="analyticFilter" icon v-bind:color="this.$store.state.isAnalyticFilterPopup ? 'deep-purple accent-4' : 'light-blue accent-4'"
                dark class="ml-5 mr-5">
                <v-icon>mdi-filter-cog</v-icon>
            </v-btn>

            <v-spacer></v-spacer>

            <v-icon v-if="$store.state.userIsOperator && distanceProfile?.isSelected" class="mr-1" color="green">mdi-checkbox-marked</v-icon>
            <span v-if="$store.state.userIsOperator && distanceProfile?.isSelected">{{ distanceProfile.name }}</span>

            <v-spacer></v-spacer>

            {{ accountName() }}

            <div class="text-center ml-2">
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon large>mdi-account</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="(item, index) in profileItems" :key="index" @click="item.func">
                            <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>

                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </v-app-bar>

        <v-main class="main-content" app>
            <v-progress-linear class="main-loading" v-if="$store.state.loading" indeterminate absolute bottom
                color="blue"></v-progress-linear>
            <router-view />
        </v-main>
    </div>
</template>

<script>
    import config from '../config'
    import ManageSettings from './settings/ManageSettings.vue'
    import ManageGeofence from './geofence/ManageGeofence.vue'

    export default {
        data() {
            return {
                drawer: null,
                menuItems: [],
                color: 'primary',
                colors: ['primary', 'blue', 'success', 'red', 'teal'],
                right: false,
                isOperator: false,
                permanent: true,
                miniVariant: false,
                expandOnHover: false,
                background: false,
                profileItems: [
                    { title: 'Выход', func: this.onExit, icon: 'mdi-exit-to-app' },
                ],
                toolbarTitle: '',
                toolbarContent: '---',
                userIsOperator: false,
            }
        },
        components: {
            ManageSettings,
            ManageGeofence,
        },
        created() {
            this.userIsOperator = this.$store.state.user.role == config.user.role.operator
        },
        mounted() {
            if (this.userIsOperator) {
                this.menuItems.push({ name: 'menus', icon: 'mdi-page-next-outline' })
                this.menuItems.push({ name: 'analytics', icon: 'mdi-chart-donut'})
                this.menuItems.push({ name: 'analytics_guests', icon: 'mdi-human-male-height'})
                this.menuItems.push({ name: 'orders', icon: 'mdi-inbox-multiple' })
                this.menuItems.push({ name: 'points', icon: 'mdi-home-group' })
                this.menuItems.push({ name: 'couriers', icon: 'mdi-car-multiple' })
                this.menuItems.push({ name: 'guests', icon: 'mdi-account-multiple' })
                this.menuItems.push({ name: 'reviews', icon: 'mdi-comment-text-multiple-outline' })
                this.menuItems.push({ name: 'logistic', icon: 'mdi-map-marker-radius-outline' })
                this.menuItems.push({ name: 'geofence', icon: 'mdi-vector-rectangle' })
                this.menuItems.push({ name: 'subscribe', icon: 'mdi-account-cash' })
                this.menuItems.push({ name: 'aboutPoint', icon: 'mdi-information' })
                this.menuItems.push({ name: 'settings', icon: 'mdi-cog' })
                if(this.$store.state.settings.services_enable){
                    this.menuItems.push({ name: 'service_orders', icon: 'mdi-account-credit-card',group:'service'})
                    this.menuItems.push({ name: 'services', icon: 'mdi-code-string',group:'service'})
                    this.menuItems.push({ name: 'workers', icon: 'mdi-account-hard-hat',group:'service'})
                }
                this.isOperator = true
            } else {
                this.menuItems.push({ name: 'menus', icon: 'mdi-page-next-outline' })
                this.menuItems.push({ name: 'analytics', icon: 'mdi-chart-donut' })
                this.menuItems.push({ name: 'analytics_guests', icon: 'mdi-human-male-height'})
                this.menuItems.push({ name: 'orders', icon: 'mdi-inbox-multiple' })
                this.menuItems.push({ name: 'guests', icon: 'mdi-account-multiple' })
                this.menuItems.push({ name: 'reviews', icon: 'mdi-comment-text-multiple-outline' })
                this.menuItems.push({ name: 'logistic', icon: 'mdi-map-marker-radius-outline' })
                this.menuItems.push({ name: 'geofence', icon: 'mdi-vector-rectangle' })
                this.menuItems.push({ name: 'subscribe', icon: 'mdi-account-cash' })
            }
            const menuItem = this.menuItems.find(item => item.name == this.$router.currentRoute.name)
            this.menuItemChange(menuItem.name)

            this.$store.state.loading = false
        },
        computed: {
            distanceProfile() {
                return this.$store.state.settings.distanceProfiles?.length > 1 ? this.$store.state.settings.distanceProfiles.find(profile => profile.isSelected) : null
            }
        },
        methods: {
            clickMarket() {
                window.open(
                  'https://drive.google.com/file/d/1YNcQFbKpM7RKTarNN7lzuvShl_brYk0b/view',
                  '_blank'
                )
            },
            clickYourCourier() {
                window.open(
                  'https://clck.ru/35vpGJ',
                  '_blank'
                )
            },
            clickReviews() {
                window.open(
                  'https://drive.google.com/file/d/1kNxFhMGBYC1eBcNUt2P_FaJ2uSpvaIkz/view',
                  '_blank'
                )
            },
            clickCollabs() {
                window.open(
                  'https://clck.ru/3CMDCB',
                  '_blank'
                )
            },
            clickHotBox() {
                window.open(
                  'https://clck.ru/3CMDWx',
                  '_blank'
                )
            },
            accountName() {
                let account_name = ''
                if (this.$store.state.user.role == config.user.role.point) {

                    if (this.$store.state.user.point) {
                        account_name += this.$store.state.user.point.name + ' - '
                    } else {
                        account_name += 'Новичок - '
                    }
                    account_name += this.$store.state.user.name
                } else if (this.$store.state.user.role == config.user.role.service) {
                    if (this.$store.state.user.service) {
                        account_name += this.$store.state.user.service.name + ' - '
                    } else {
                        account_name += 'Новичок - '
                    }
                    account_name += this.$store.state.user.full_name
                } else {
                    account_name += this.$store.state.user.name
                }
                return account_name
            },
            onProfile() {},
            onExit() {
                this.$store.dispatch('logout').then(() => this.$router.push({ name: 'login' }))
            },
            menuItemChange(name) {
                this.toolbarTitle = this.$t( name + '.title')

                this.$store.state.isLogistic = false
                this.$store.state.isSettings = false
                this.$store.state.isGeofence = false
                this.$store.state.isAnalytic = false
                
                if (this.toolbarTitle == 'Карта' && !this.$store.state.userIsPoint) {
                    this.$store.state.isLogistic = true
                } else if (this.toolbarTitle == 'Настройки') {
                    this.$store.state.isSettings = true
                } else if (this.toolbarTitle == 'Геозоны') {
                    this.$store.state.isGeofence = true
                } else if(this.toolbarTitle == 'Аналитика'&&this.$store.state.userIsOperator){
                    this.$store.state.isAnalytic=true
                }
            },
            windowOpenClose(service) {
                if(service) this.$store.state.isServiceWindowClose = !this.$store.state.isServiceWindowClose
                else this.$store.state.isOrderWindowClose = !this.$store.state.isOrderWindowClose
            },
            analyticFilter(){
                this.$store.state.isAnalyticFilterPopup = !this.$store.state.isAnalyticFilterPopup
            }
        },
    }
</script>

<style lang="stylus" scoped>
    .v-tooltip__content {
        background-color: #222;
    }
    .v-sheet.v-card
      border-radius 0
    .main
        width 100%
        height 100%
    .v-card__text
        padding 0
    .main-bar
    .main-content
        padding 0 !important
        height 100%
    .nav-cont
        height 100vh
    .v-divider
        padding-bottom 8px
    .v-application
        height 100% !important
    .v-main
        height 100% !important
    .container
        height 100%

    .v-list-item--two-line .v-list-item__avatar:not(.v-list-item__avatar--horizontal)
        margin-top 0
        margin-bottom 0

    .v-application--is-ltr .v-list-item__icon:first-child
        margin-right 14px
        margin-left 6px

    .shapka
        width 70px
        position absolute
        left 8px
        top -8px

    .btn-adds {
        border-radius: 3px !important;
        z-index: 1;
        font-size: 16px !important;
        color: #0C043F !important;
        margin: 2px auto;
        margin-top: 2px;
        padding: 6px 8px;
        font-weight: 600;
        width: 200px;
    }

    .btn-adds-left {
        margin: 3px 0px;
        padding-left: 12px;
        text-align: left;
    }

    .break-line{
        border:none;
        height:0.1rem;
        background-color: #000e2d
    }
    .btn-yellow {
        background: linear-gradient(49.06deg, #F9C801 20.38%, #FFF854 135.95%);
    }

    .btn-yellow:hover {
        background: linear-gradient(49.06deg, #bf9901 20.38%, #d0cb46 135.95%);
    }

    .btn-pure {
        background: radial-gradient(140.1% 140.1% at 51.82% -42.15%, #d161ff 0, #7d31b8 100%);
        color: #eee !important;
    }

    .btn-pure:hover {
        background: radial-gradient(140.1% 140.1% at 51.82% -42.15%, #bd57e7 0, #6f2ba4 100%);
    }

    .btn-clear {
        color: #eee !important;
    }

    .btn-clear:hover {
        background-color: #1d2f68;
    }

    .icon-adds-red {
        color: #F44336;
        margin: 0 4px;
    }
    .icon-adds-yellow {
        color: #fbd617;
        margin: 0 4px;
    }

</style>

<style lang="stylus">
    .order-card
        cursor default
        margin 10px 15px
        font-size 13px

    .order-id
        cursor default
        margin 0 15px
        color #bbb

    .item-order
        background-color grey
        color white
        cursor default
        margin 1px
        padding 0 4px
        border-radius 2px
        font-size 12px
        font-weight 600
        box-shadow 1px 1px 1px grey
        height 18px
        line-height 18px

    .main-loading
        z-index 10000
        top 0px

    .v-snack__content
        display flex
        align-items center
        justify-content space-between
        font-size 16px
        font-weight 400
        color white

</style>
